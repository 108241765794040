<template>
    <div class="ps-3 pe-3 pt-1 pb-1" v-if="title">
        <div v-if="state == 'settled'" class="uk-timeline">
            <div class="uk-timeline-item">
                <div class="uk-timeline-icon">
                    <span class="uk-badge"><span uk-icon="check"></span></span>
                    <span class="uk-badge"><i class="fas fa-check"></i></span>
                </div>
                <div class="timeline-box ms-5 p-3" :class="{ 'active' : id == parseInt($route.query[queryParamName])}" @click="clickTo">
                    <h5>{{ title }}</h5>
                    <p>{{ content }}</p>
                </div>
            </div>
        </div>
        <div v-else-if="state == 'running'" class="uk-timeline running">
            <div class="uk-timeline-item">
                <div class="uk-timeline-icon">
                    <span class="uk-badge"><i class="fas fa-circle"></i></span>
                </div>
                <div class="timeline-box ms-5 p-3" :class="{ 'active' : id == parseInt($route.query[queryParamName])}" @click="clickTo">
                    <h5>{{ title }}</h5>
                    <p>{{ content }}</p>
                </div>
            </div>
        </div>
        <div v-else-if="state == 'disabled'" class="uk-timeline disabled">
            <div class="uk-timeline-item">
                <div class="uk-timeline-icon">
                    <span class="uk-badge"><i class="fas fa-circle"></i></span>
                </div>
                <div class="timeline-box ms-5 p-3">
                    <h5>{{ title }}</h5>
                    <p>{{ content }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'id', 
        'title', 
        'content',
        'queryParamName', 
        'state' //disabled, running & settled
    ],
    methods: {
        clickTo(){
            if(this.id == parseInt(this.$route.query[this.queryParamName])) return ''

            this.$router.push({ query: { subtopic : this.id }})
            this.$emit('view-selected-timeline', this.id)
        },
    },
}
</script>

<style lang="scss">
@import "./../assets/scss/color.scss";
@import "./../assets/scss/main.scss";
// @import url('https://cdn.jsdelivr.net/npm/uikit@3.4.2/dist/css/uikit.min.css');

.uk-timeline{
    &.disabled {
        .uk-timeline-item {
            &::before {
                background: unset;
                // background: $gray-light-02;
            }

            .timeline-box {
                &:hover {
                    background-color: unset;
                    border-radius: unset;
                }
            }

            .uk-timeline-icon {
                .uk-badge {
                    color: $gray-light-02;
                }
            }
        }
    }

    &.running {
        .uk-timeline-item {
            // &::before {
            //     background: $gray-light-02;
            // }
        }
    }

    .uk-timeline-item {
        display: flex;
        position: relative;

        // &::before {
        //     background: $green;
        //     content: "";
        //     height: 100%;
        //     left: 19px;
        //     position: absolute;
        //     top: 45px;
        //     width: 4px;
        //     // z-index: -1;
        // }

        .timeline-box {
                cursor: pointer;
                width: 100%;

                &:hover {
                    background-color: #f7f7f7;
                    border-radius: 7px;
                }

                &.active {
                    background-color: #f7f7f7;
                    border-radius: 7px;
                }
        }

        .uk-card {
            max-height: 300px;
        }

        .uk-timeline-content {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            padding: 0 0 0 1rem;
            color: rgba(102, 112, 133, 1);
        }

        .uk-timeline-icon {
            .uk-badge {
                width: 40px;
                height: 40px;
                background-color: rgba(82, 145, 44, 1);
                color: #fff;
                position: absolute;
                top: 10px;
                border-radius: 50%;
                padding-left: 12px;
                padding-top: 8px;
            }
        }

    }    
}
</style>